const IS_DEV = process.env.NODE_ENV !== 'development' ? false : false; // true : backup, false: production

export const API_URL_BASE = IS_DEV
  ? 'http://localhost:6006/ske-graphql'
  : 'https://api-22233.scoresnow.net/ske-graphql';

export const URL_UPLOAD = IS_DEV
  ? 'http://localhost:6005/uploads'
  : 'https://api-22233.scoresnow.net/uploads';

export const API_URL_REPLAY = IS_DEV
  ? 'http://127.0.0.1:6006'
  : 'https://site21.coolguyuk.info';

export const API_URL_EXPORT = IS_DEV
  ? 'http://localhost:6006'
  : 'https://api-22233.scoresnow.net';

export const ENCODE_SECRET_KEY =
  process.env?.ENCODE_SECRET_KEY || 'QiLCJhbGciOiJSUzI';

export const URL_REPLAY = 'https://site01.coolguyuk.xyz/replay';

export const URL_API_FOOTBALL = 'https://v3.football.api-sports.io';
export const TOKEN_API_FOOTBALL = '54883420494ac499b7788e8dbcb84356';

export const SPORTS = [
  'Tennis',
  'Cricket',
  'Horse Racing',
  'Motorsport',
  'Basketball',
];

export const TEAMS_DATA = [
  {
    id: 529,
    name: 'Barcelona',
    code: 'BAR',
    country: 'Spain',
    founded: 1899,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/529.png',
  },
  {
    id: 541,
    name: 'Real Madrid',
    code: 'REA',
    country: 'Spain',
    founded: 1902,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/541.png',
  },
  {
    id: 157,
    name: 'Bayern Munich',
    code: 'BAY',
    country: 'Germany',
    founded: 1900,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/157.png',
  },
  {
    id: 496,
    name: 'Juventus',
    code: 'JUV',
    country: 'Italy',
    founded: 1897,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/496.png',
  },
  {
    id: 530,
    name: 'Atletico Madrid',
    code: 'MAD',
    country: 'Spain',
    founded: 1903,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/530.png',
  },
  {
    id: 85,
    name: 'Paris Saint Germain',
    code: 'PAR',
    country: 'France',
    founded: 1970,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/85.png',
  },
  {
    id: 50,
    name: 'Manchester City',
    code: 'MAC',
    country: 'England',
    founded: 1880,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/50.png',
  },
  {
    id: 165,
    name: 'Borussia Dortmund',
    code: 'DOR',
    country: 'Germany',
    founded: 1909,
    national: false,
    logo: 'https://media-2.api-sports.io/football/teams/165.png',
  },
  {
    id: 49,
    name: 'Chelsea',
    code: 'CHE',
    country: 'England',
    founded: 1905,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/49.png',
  },
  {
    id: 536,
    name: 'Sevilla',
    code: 'SEV',
    country: 'Spain',
    founded: 1890,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/536.png',
  },
  {
    id: 42,
    name: 'Arsenal',
    code: 'ARS',
    country: 'England',
    founded: 1886,
    national: false,
    logo: 'https://media-2.api-sports.io/football/teams/42.png',
  },
  {
    id: 33,
    name: 'Manchester United',
    code: 'MUN',
    country: 'England',
    founded: 1878,
    national: false,
    logo: 'https://media-2.api-sports.io/football/teams/33.png',
  },
  {
    id: 211,
    name: 'Benfica',
    code: 'BEN',
    country: 'Portugal',
    founded: 1904,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/211.png',
  },
  {
    id: 492,
    name: 'Napoli',
    code: 'NAP',
    country: 'Italy',
    founded: 1904,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/492.png',
  },
  {
    id: 40,
    name: 'Liverpool',
    code: 'LIV',
    country: 'England',
    founded: 1892,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/40.png',
  },
  {
    id: 497,
    name: 'AS Roma',
    code: 'ROM',
    country: 'Italy',
    founded: 1927,
    national: false,
    logo: 'https://media-3.api-sports.io/football/teams/497.png',
  },
  {
    id: 46,
    name: 'Leicester',
    code: 'LEI',
    country: 'England',
    founded: 1884,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/46.png',
  },
  {
    id: 168,
    name: 'Bayer Leverkusen',
    code: 'BAY',
    country: 'Germany',
    founded: 1904,
    national: false,
    logo: 'https://media-2.api-sports.io/football/teams/168.png',
  },
  {
    id: 47,
    name: 'Tottenham',
    code: 'TOT',
    country: 'England',
    founded: 1882,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/47.png',
  },
  {
    id: 489,
    name: 'AC Milan',
    code: 'MIL',
    country: 'Italy',
    founded: 1899,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/489.png',
  },
  {
    id: 9568,
    name: 'Inter Miami',
    code: null,
    country: 'USA',
    founded: 2018,
    national: false,
    logo: 'https://media-2.api-sports.io/football/teams/9568.png',
  },
  {
    id: 505,
    name: 'Inter',
    code: 'INT',
    country: 'Italy',
    founded: 1908,
    national: false,
    logo: 'https://media-1.api-sports.io/football/teams/505.png',
  },
  {
    id: 10,
    name: 'England',
    code: 'ENG',
    country: 'England',
    founded: 1863,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/10.png',
  },
  {
    id: 26,
    name: 'Argentina',
    code: 'ARG',
    country: 'Argentina',
    founded: 1893,
    national: true,
    logo: 'https://media-3.api-sports.io/football/teams/26.png',
  },
  {
    id: 6,
    name: 'Brazil',
    code: 'BRA',
    country: 'Brazil',
    founded: 1914,
    national: true,
    logo: 'https://media-3.api-sports.io/football/teams/6.png',
  },
  {
    id: 27,
    name: 'Portugal',
    code: 'POR',
    country: 'Portugal',
    founded: 1914,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/27.png',
  },
  {
    id: 768,
    name: 'Italy',
    code: 'ITA',
    country: 'Italy',
    founded: 1898,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/768.png',
  },
  {
    id: 1108,
    name: 'Scotland',
    code: 'SCO',
    country: 'Scotland',
    founded: 1873,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/1108.png',
  },
  {
    id: 9,
    name: 'Spain',
    code: 'SPA',
    country: 'Spain',
    founded: 1913,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/9.png',
  },
  {
    id: 2379,
    name: 'Venezuela',
    code: 'VEN',
    country: 'Venezuela',
    founded: 1926,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/2379.png',
  },
  {
    id: 1118,
    name: 'Netherlands',
    code: 'NET',
    country: 'Netherlands',
    founded: 1889,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/1118.png',
  },
  {
    id: 2379,
    name: 'Venezuela',
    code: 'VEN',
    country: 'Venezuela',
    founded: 1926,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/2379.png',
  },
  {
    id: 8,
    name: 'Colombia',
    code: 'COL',
    country: 'Colombia',
    founded: 1924,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/8.png',
  },
  {
    id: 773,
    name: 'Slovakia',
    code: 'SLO',
    country: 'Slovakia',
    founded: 1993,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/773.png',
  },
  {
    id: 775,
    name: 'Austria',
    code: 'AUS',
    country: 'Austria',
    founded: 1904,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/775.png',
  },
  {
    id: 21,
    name: 'Denmark',
    code: 'DEN',
    country: 'Denmark',
    founded: 1889,
    national: true,
    logo: 'https://media.api-sports.io/football/teams/21.png',
  },
];
